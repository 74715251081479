import mock from "../mock"
export let roomNumbersList = [
  {
    id: 1,
    active_status: true,
    room_no: 1001,
    room_size : 550,    
    max_pax : 4,
    allow_smoking : true,
    allow_disabled_person : true,
    bed_option_id : 5,
    room_type : "Deluxe Pool View Room",
    building_type : "Deluxe Room",
    associated_floor : "1st Floor",       
    desc: "Lorem ipsum doler sit amith",    
  },  
  {
    id: 2,
    active_status: true,
    room_no: 1002,
    room_size : 450,    
    max_pax : 3,
    allow_smoking : false,
    allow_disabled_person : true,
    bed_option_id : 3,
    room_type : "Standard Room",
    building_type : "Standard Room",
    associated_floor : "3rd Floor",       
    desc: "Lorem ipsum doler sit amith",    
  }, 
  {
    id: 3,
    active_status: true,
    room_no: 1003,
    room_size : 475,    
    max_pax : 2,
    allow_smoking : false,
    allow_disabled_person : false,
    bed_option_id : 3,
    room_type : "Single Room",
    building_type : "Standard Room",
    associated_floor : "2nd Floor",       
    desc: "Lorem ipsum doler sit amith",    
  }, 
  {
    id: 4,
    active_status: true,
    room_no: 1004,
    room_size : 520,    
    max_pax : 3,
    allow_smoking : false,
    allow_disabled_person : false,
    bed_option_id : 6,
    room_type : "Allamanda Room",
    building_type : "Allamanda Room",
    associated_floor : "4th Floor",       
    desc: "Lorem ipsum doler sit amith",    
  }, 
  {
    id: 5,
    active_status: true,
    room_no: 1005,
    room_size : 620,    
    max_pax : 4,
    allow_smoking : false,
    allow_disabled_person : false,
    bed_option_id : 2,
    room_type : "Deluxe Pool View Family Room",
    building_type : "Deluxe Room",
    associated_floor : "1st Floor",       
    desc: "Lorem ipsum doler sit amith",    
  }, 
  
]

// GET DATA
mock.onGet("/api/roomNumbersList/initial-data").reply(response => {
  return [200, roomNumbersList]
})

mock.onGet("/api/roomNumbersList/data").reply(response => {
  let { page, perPage } = response  
  let totalPages = Math.ceil(roomNumbersList.length / perPage)
  if (page !== undefined && perPage !== undefined) {
    let calculatedPage = (page - 1) * perPage    
    let calculatedPerPage = page * perPage
    return [
      200,
      { data: roomNumbersList.slice(calculatedPage, calculatedPerPage), totalPages }
    ]
  } else {
    return [
      200,
      { data: roomNumbersList.slice(0, 10), totalPages: Math.ceil(roomNumbersList.length / 10) }
    ]
  }
})

// UPDATE DATA
mock.onPost("/api/roomNumbersList/update-data").reply(request => {
  //let data = JSON.parse(request.data).obj
  roomNumbersList.map(item => {
    return item    
  })
  return [200]
})

// Add DATA
mock.onPost("/api/roomNumbersList/add-data").reply(request => {
  let data = JSON.parse(request.data).obj
  let highestId = Math.max.apply(
    Math,
    roomNumbersList.map(i => i.id)
  )
  roomNumbersList.unshift({
    ...data,
    id: highestId + 1,
    
  })
  return [200]
})

// DELETE DATA
mock.onPost("/api/roomNumbersList/delete-data").reply(request => {
  let data = JSON.parse(request.data).obj
  let index = roomNumbersList.findIndex(item => item.id === data.id)
  roomNumbersList.splice(index, 1)
  return [200]
})

// DELETE SELECTED DATA
mock.onPost("/api/roomNumbersList/delete-selected").reply(request => {
  let data = JSON.parse(request.data).arr
  let reducedArray
  ;[roomNumbersList, data].reduce((a, b) => {
    let c = b.map(j => j.id)
    return (reducedArray = a.filter(i => !c.includes(i.id)))
  })
  roomNumbersList = reducedArray
  return [200]
})
