import mock from "../mock"
export let bedsList = [
  {
    id: 1,
    active_status: true,
    name: "Single",
    using_for: "Child",
    width: 30,
    length: 75,
    no_of_pax : 1,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 2,
    active_status: false,
    name: "Double",
    using_for: "Both",
    width: 54,
    length: 74,
    no_of_pax : 2,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 3,
    active_status: true,
    name: "Twin",
    using_for: "Adult",
    width: 38,
    length: 80,
    no_of_pax : 2,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 4,
    active_status: false,
    name: "Triple",
    using_for: "Both",
    width: 75,
    length: 80,
    no_of_pax : 3,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 5,
    active_status: false,
    name: "King",
    using_for: "Both",
    width: 76,
    length: 80,
    no_of_pax : 4,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 6,
    active_status: true,
    name: "Queen",
    using_for: "Both",
    no_of_pax : 3,
    width: 60,
    length: 80,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 7,
    active_status: true,
    name: "Canopy",
    using_for: "Both",
    width: 64,
    length: 74,
    no_of_pax : 2,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 8,
    active_status: true,
    name: "Divan",
    using_for: "Both",
    width: 66,
    length: 75,
    no_of_pax : 2,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 9,
    active_status: true,
    name: "Olympic Queen",
    using_for: "Both",
    width: 66,
    length: 80,
    no_of_pax : 2,
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 10,
    active_status: true,
    name: "California King",
    using_for: "Both",
    width: 72,
    length: 84,
    no_of_pax : 2,
    desc: "Lorem ipsum doler sit amith"
  },
]

// GET DATA
mock.onGet("/api/bedsList/initial-data").reply(response => {
  return [200, bedsList]
})

mock.onGet("/api/bedsList/data").reply(response => {
  let { page, perPage } = response  
  let totalPages = Math.ceil(bedsList.length / perPage)
  if (page !== undefined && perPage !== undefined) {
    let calculatedPage = (page - 1) * perPage    
    let calculatedPerPage = page * perPage
    return [
      200,
      { data: bedsList.slice(calculatedPage, calculatedPerPage), totalPages }
    ]
  } else {
    return [
      200,
      { data: bedsList.slice(0, 10), totalPages: Math.ceil(bedsList.length / 10) }
    ]
  }
})

// UPDATE DATA
mock.onPost("/api/bedsList/update-data").reply(request => {
  //let data = JSON.parse(request.data).obj
  bedsList.map(item => {
    return item    
  })
  return [200]
})

// Add DATA
mock.onPost("/api/bedsList/add-data").reply(request => {
  let data = JSON.parse(request.data).obj
  let highestId = Math.max.apply(
    Math,
    bedsList.map(i => i.id)
  )
  bedsList.unshift({
    ...data,
    id: highestId + 1,
    
  })
  return [200]
})

// DELETE DATA
mock.onPost("/api/bedsList/delete-data").reply(request => {
  let data = JSON.parse(request.data).obj
  let index = bedsList.findIndex(item => item.id === data.id)
  bedsList.splice(index, 1)
  return [200]
})

// DELETE SELECTED DATA
mock.onPost("/api/bedsList/delete-selected").reply(request => {
  let data = JSON.parse(request.data).arr
  let reducedArray
  ;[bedsList, data].reduce((a, b) => {
    let c = b.map(j => j.id)
    return (reducedArray = a.filter(i => !c.includes(i.id)))
  })
  bedsList = reducedArray
  return [200]
})
