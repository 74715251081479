import axios from "axios";
import { generateAppConfig } from "../config";
require("dotenv").config();
export const API_BASE_URL = generateAppConfig().backendUrl;
export const APP_API_BASE_URL = generateAppConfig().appBackendUrl;
export const FILE_UPLOAD_URL = process.env.REACT_APP_FILE_UPLOAD_URL;

export const performRequest = async (method, url, params, urlFrom="admin") => {
  let body = "data";
  if (method === "get") {
    body = "params";
  }
  console.log(url)
  const baseURL = urlFrom === "admin"? API_BASE_URL: APP_API_BASE_URL;
  const accessToken = JSON.parse(localStorage.getItem("accessToken") || "{}");
    const config = {
      method,
      url,
      baseURL: baseURL,
      [body]: params || {},
    };
    config.headers = {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + accessToken.accessToken,
    };
    return axios.request(config);
  
};
