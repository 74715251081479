import demoLogo from "./logo/logo.png";
import iconLogo from "./logo/arclogo.png";
import summerbaylogo from "../assets/img/logo/sbay-app-logo.png"

let  logo = demoLogo

let applicationName = "Travoxios Digital Intelligence Platform (TDIP)"

let siteName = "https://travoxis.com/"

let FooterName = "Travoxis Technology SDN BHD"


export {
    logo,
    iconLogo,
    applicationName,
    siteName,
    FooterName
}
