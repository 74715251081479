import React from "react"
import {
  //NavItem,
  //NavLink,
  Badge,
  UncontrolledDropdown,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import axios from "axios"
import * as Icon from "react-feather"
//import classnames from "classnames"
import ReactCountryFlag from "react-country-flag"
//import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import { history } from "../../../history"
import { IntlContext } from "../../../utility/context/Internationalization"
import Currency from './CurrencyComponent'
import { reservationService } from "../../../service";
import { getWalletInfo } from "../../../redux/actions/wallet/action.wallet";
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import currenyFormatter from "../../../views/commonComponents/currenyFormatter"

const handleNavigation = (e, path) => {
  e.preventDefault()
  history.push(path)
}

const UserDropdown = props => {
  const { logout, isAuthenticated } = useAuth0()
  return (
    <DropdownMenu right className="usernav-dropdown">
      <DropdownItem
        tag={Link}
        to="/profile"
      >
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Profile</span>
      </DropdownItem>
      <DropdownItem
        tag={Link}
        to="/credits"
      >
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">My Transactions</span>
      </DropdownItem>
      <DropdownItem tag={Link} to="/my-promotions"
      >
        <Icon.Star size={14} className="mr-50" />
        <span className="align-middle">My Promotions</span>
      </DropdownItem>
      <DropdownItem tag={Link} to="/offers">
        <Icon.File size={14} className="mr-50" />
        <span className="align-middle">Offers</span>
      </DropdownItem>
      {/* <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/email/inbox")}
      >
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/todo/all")}
      >
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        href="#"
        onClick={e => handleNavigation(e, "/chat")}
      >
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#" onClick={e => handleNavigation(e, "/ecommerce/wishlist")}>
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem> */}
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        href="/pages/login"
        onClick={e => {
          localStorage.clear("accesToken")
          // e.preventDefault()
          // if (isAuthenticated) {
          //   return logout({
          //     returnTo: window.location.origin + process.env.REACT_APP_PUBLIC_PATH
          //   })
          // } else {
          //   const provider = props.loggedInWith
          //   if (provider !== null) {
          //     if (provider === "jwt") {
          //       return props.logoutWithJWT()
          //     }
          //     if (provider === "firebase") {
          //       return props.logoutWithFirebase()
          //     }
          //   } else {
          //     history.push("/pages/login")
          //   }
          // }

        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    let amount = 0
    if(props.wallet){
      const { creditedAmount =0, debitedAmount = 0, totalReqAmount = 0} = props.wallet;
      amount = (creditedAmount - debitedAmount - totalReqAmount);
      // amount = (props.wallet.creditedAmount - props.wallet.debitedAmount - props.wallet.totalReqAmount)
      }
    return {
      walletAmount: amount
    }
    return null
  }
  state = {
    navbarSearch: false,
    langDropdown: false,
    langCurrencyDropdown: false,  
    walletAmount: 0  
  }

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
    // reservationService().getWallet().then(res=>{
    //   console.log(res.data,'LLLLLLLLLLLLLLLLLLL')
    //   const {creditedAmount, debitedAmount} = res.data
    //   this.setState({ walletAmount: (creditedAmount-debitedAmount)})
    // })
    this.props.getWalletInfo();
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }

  removeItem = id => {
    let cart = this.state.shoppingCart

    let updatedCart = cart.filter(i => i.id !== id)

    this.setState({
      shoppingCart: updatedCart
    })
  }

  handleLangDropdown = () =>
    this.setState({ langDropdown: !this.state.langDropdown })
  
  handleLangCurrencyDropdown = () =>{   
  this.setState({ langCurrencyDropdown: !this.state.langCurrencyDropdown })
  }

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        {/*  LANGUAGE SWITCH OPTION  */}
        <IntlContext.Consumer>
          {context => {
            let langArr = {
              "en": "English",
              "my": "Malay",
              "cn": "Chinese",             
            }
            return (
              <>                
                <Dropdown
                tag="li"
                className="dropdown-language  border-left border-right nav-item d-flex align-items-center pl-50"
                isOpen={this.state.langCurrencyDropdown}
                toggle={this.handleLangCurrencyDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link mr-0 p-50"
                >                  
                  <span className="d-sm-inline-block align-middle">
                  {langArr[context.state.locale]}
                  </span> {' - '}
                  <span className="d-sm-inline-block align-middle">
                   {context.state.currency}
                  </span>
                  <Icon.ChevronDown       
                    size={15} className="ml-50"
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <div className="p-1" style={{minWidth:"250px"}}>
                    <div className="reg-setting-heading mb-2">
                      <h6 className="font-weight-bold">Regional Setting</h6>
                    </div>
                    <div className="form-group">
                      <label className="mb-50">Language</label>
                      <select className="custom-select" value = {context.state.locale} onChange = {(e) =>context.switchLanguage(e.target.value) }>
                        <option value ="en">English</option>
                        <option value ="my">Malay</option>
                        <option value = "cn">Chinese</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label  className="mb-50">Currency</label>
                      {/* <select className="custom-select">
                        <option>USD-US Dollar</option>
                        <option>MYR-Malaysian Ringgit</option>
                        <option>INR-Indian Rupee</option>
                        <option>SGD-Singapore Dollar</option>
                        <option>CNY-China Yuan</option>
                      </select> */}
                       <Currency toggle={this.handleLangCurrencyDropdown}/>
                    </div>
                    <div className="text-right">
                      <button type="button" className="btn btn-primary btn-sm">Save</button>
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>

              </>
            )
          }}
        </IntlContext.Consumer>
        {/* <div class="opt-menu mr-3">
        <a  class="opt-menu-title d-flex justify-center align-center">
                <span class="mr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path>
                        <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
                    </svg>
                </span>
                <div>
                    <small>My Account</small>
                    <div style={{fontSize:"13px"}}>
                        <b>RM 1000.00</b>
                    </div>
                </div>            
            </a>        
        </div> */}
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item d-flex align-items-center px-50 border-right"
        onClick={()=>{ history.push("/credits")}}>
          <span>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path>
                  <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
              </svg>
          </span>
          <div className="ml-50">
              <div className="mb-75 d-none d-sm-block">My Account</div>
              <div className="">
                  <b>RM {currenyFormatter(this.state.walletAmount)}</b>
              </div>
          </div>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item d-flex align-items-center pl-50">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link px-0">
            <div className="user-nav d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              {/* <span className="user-status">Available</span> */}
            </div>
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="30"
                width="30"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}

const mapStateToProps = state => {
  return {
    wallet: state.wallet.walletDetails.data
  }
}

export default connect(mapStateToProps, {
  getWalletInfo
})(NavbarUser)