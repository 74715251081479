import React from "react";
import * as Icon from "react-feather";

const horizontalMenuConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    navLink: "/dashboard",
    icon: <Icon.Home size={16} />,
  },
  {
    id: "booking",
    title: "My Bookings",
    type: "dropdown",
    icon: <Icon.Grid size={16} />,
    children: [
      {
        id: "createBooking",
        title: "New Booking",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/",
        permissions: ["admin", "editor"],
      },
      {
        id: "bookingList",
        title: "Manage Bookings",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/booking/listing",
        permissions: ["admin", "editor"],
      },
      {
        id: "preBookingList",
        title: "Manage Draft Bookings",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/draft-booking/listing",
        permissions: ["admin", "editor"],
      },
      {
        id: "feedbacks",
        title: "Feedback",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/my-feedback",
        permissions: ["admin", "editor"],
      }
    ],
    
  },
  {
    id: "Offers",
    title: "New promotions",
    type: "item",
    navLink: "/offers",
    icon: <Icon.Home size={16} />,
  },
  // {
  //   id: "Offers",
  //   title: "Offers",
  //   type: "item",
  //   navLink: "",
  //   icon: <Icon.Home size={16} />,
  //   children: [
  //     {
  //       id: "buyPackage",
  //       title: "Offers",
  //       type: "item",
  //       icon: <Icon.Circle size={10} />,
  //       navLink:  "/offers",
  //       permissions: ["admin", "editor"],
  //     },
  //     {
  //       id: "listPackage",
  //       title: "List Package",
  //       type: "item",
  //       icon: <Icon.Circle size={10} />,
  //       navLink: "/package-listing",
  //       permissions: ["admin", "editor"],
  //     }
  //   ]
  // },
  {
    id: "myPromotions",
    title: "Redeem promotions",
    type: "item",
    navLink: "/my-promotions",
    icon: <Icon.Home size={16} />,
  },
  // {
  //   id: "myPromotions",
  //   title: "My Promotions",
  //   type: "item",
  //   icon: <Icon.Home size={16} />,
  //   children: [
  //     {
  //       id: "byPromoCode",
  //       title: "Buy Promo Code",
  //       type: "item",
  //       icon: <Icon.Circle size={10} />,
  //       navLink:  "/promocode",
  //       permissions: ["admin", "editor"],
  //     },
  //     {
  //       id: "listPromoCode",
  //       title: "List Promo Code",
  //       type: "item",
  //       icon: <Icon.Circle size={10} />,
  //       navLink: "/promocode-listing",
  //       permissions: ["admin", "editor"],
  //     }
  //   ]
  // },
  // {
  //   id: "myCredits",
  //   title: "My Credits",
  //   type: "item",
  //   navLink: "/credits",
  //   icon: <Icon.Home size={16} />,
  // }
  // ,
  // {
  //   id: "myAccount",
  //   title: "My Account",
  //   type: "item",
  //   navLink: "/myAccount",
  //   icon: <Icon.Home size={16} />,
  // },
  // {
  //   id: "ExploreSummerbay",
  //   title: "Explore Summerbay",
  //   type: "item",
  //   navLink: "/explore-summer-bay",
  //   icon: <Icon.Home size={16} />,
  // },
  // {
  //   id: "DivingConcernForm",
  //   title: "Diving Concern Form",
  //   type: "item",
  //   navLink: "/diving-concern-form",
  //   icon: <Icon.Home size={16} />,
  // },
  {
    id: "referral",
    title: "Referral",
    type: "item",
    icon: <Icon.Circle size={10} />,
    navLink: "/referral-page"
  },
  {
    id: "paymentHistory",
    title: "Payment History",
    type: "item",
    navLink: "/payment-history",
    icon: <Icon.Home size={16} />,
  },
  // {
  //   id: "walletPayout",
  //   title: "Wallet Payout",
  //   type: "item",
  //   navLink: "/wallet-payout",
  //   icon: <Icon.Circle size={10} />,
    
  // }
];

export default horizontalMenuConfig;
