import mock from "./mock"
import "./apps/dataView"
import "./tables/aggrid"
import "./autoComplete/autoComplete"
import "./navbar/navbarSearch"
import "./navbar/navbarBookmarkSearch"
import "./auth/authentication"
import "./apps/email"
import "./apps/chat"
import "./apps/todo"
import "./apps/calendar"
import "./apps/usersList"
import "./apps/departmentsList"
import "./apps/rolesList"
import "./apps/buildingsList"
import "./apps/bedsList"
import "./apps/amenitiesList"
import "./apps/roomTypesList"
import "./apps/roomNumbersList"
mock.onAny().passThrough()
