import { performRequest } from './api-handler';
import { URLS } from '../utility/urls';
export default () => {
    const { GET_PRMOTIONS,
        GET_PROMOCODE,
        GET_PROMOLIST,
        GET_PACKAGES_BY_ID,
        GET_ROOM_TYPES_ID,
        GET_USER_BOUGHT_PACKAGES,
        GET_USER_BOUGHT_VOUCHORS,
        GET_EVENTS,
        GET_EVENT_VACANT, 
        GET_EVENTS_BYUSER } = URLS;
    return {
        getPromotions: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PRMOTIONS, body, "user"),
        getPackagesById: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_PACKAGES_BY_ID.replace(":_id", body.id), body, "user"),
        getRoomTypesId: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_ROOM_TYPES_ID.replace(":_id", body.id), body, "user"),
        getPromoCode: (body = {}, method = 'get', type="cash") =>
            performRequest(method ? method : 'get', `${GET_PROMOCODE}?type=${type}`, body, "user"),
        getPromoCodeDetails: (body={}, method='get', url) =>
            performRequest( method ? method:'get', url ||GET_PROMOLIST, body ),
        getuserBoughtPackages: (body={}, method='get', url) =>
            performRequest( method ? method:'get',GET_USER_BOUGHT_PACKAGES, body, "user" ),
        getUserVouchers: (body={}, method='get', url) =>
            performRequest( method ? method:'get',GET_USER_BOUGHT_VOUCHORS, body, "user" ),
        getEvents: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_EVENTS, body),
        getEventVacant: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', GET_EVENT_VACANT, body),
        getEventsByuser: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_EVENTS_BYUSER.replace(":_id", body._id), body, "user"),
    }
}