import React, {useState,useEffect,useContext} from 'react'
import {APP_API_BASE_URL} from '../../../service/api-handler'
import {IntlContext} from '../../../utility/context/Internationalization'

function CurrencyComponent({toggle}) {

 const [apiData, setData] = useState([])
 const [dropDown,setDropDown] = useState(false)
 const currencyState = useContext(IntlContext)
 useEffect(() => {
  const fetchData = async () => {
    try {
        const response = await fetch(`${APP_API_BASE_URL}/api/currency`)
        const {data:Currency = []} = await response.json()
          
        if(Currency && Currency.length){
            setData(Currency)
        }      

               

    } catch (error) {
        console.log(error)

    }
  }    
    
  fetchData()

 }, [])

 const switchCurrency  = (e) => {
  const {foreignConvRate,myConvRate, currencyId: {symbol_native} = {}} = apiData.find(item => item.currencyId.code === e.target.value)
  
  currencyState.switchCurrecny(e.target.value,foreignConvRate,myConvRate,symbol_native )
  toggle()
 }


 
    return (
       <select className="custom-select" value = {currencyState.state.currency} onChange = {(e) =>switchCurrency(e)}>
         {apiData && apiData.length && apiData.map(currency => (
      <option key= {currency._id} id ={currency._id} value = {currency?.currencyId?.code} >{currency?.currencyId?.code}-{currency?.currencyId?.name}</option> 
         ))}
                       
                      </select> 
    )
}

export default CurrencyComponent
