import React from "react"
import ScrollToTop from "react-scroll-up"
import {siteName, FooterName} from '../../../configs/resort'
import { Button } from "reactstrap"
import { ArrowUp } from "react-feather"
import classnames from "classnames"

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"]
  return (
    <footer
      className={classnames("footer footer-light px-1", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <p className="mb-0 d-flex justify-content-between align-items-center font-small-2">
        <span className="">
          Copyrights &copy; {new Date().getFullYear()}
          <a
            href={process.env.REACT_APP_SUMMERBAY_SITE}
            target="_blank"
            rel="noopener noreferrer"
          >
            {FooterName}
          </a>
          All rights reserved.
          version v2.1.1.2
        </span>
        <span className="">   
          
          <span className="">Powered by</span>{" "}          
          <a
            href="https://travoxis.com"
            target="_blank"
            rel="noopener noreferrer"
          >
          Travoxis Technology SDN BHD
          </a>
          
        </span>
      </p>
      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
  )
}

export default Footer
