import { combineReducers } from "redux";
import bookingListingReducers from "./bookingListing"
import reserveReducers from "./reservation"
import customizer from "./customizer/"
import navbar from "./navbar/Index"
import auth from "./auth/"
import walletReducers from "./wallet"



const rootReducer = combineReducers({
  bookingLists:bookingListingReducers,
  reservation: reserveReducers,
  customizer: customizer,
  navbar: navbar,
  auth: auth,
  wallet: walletReducers


})

export default rootReducer
