export const generateAppConfig = () =>{
    let exportResult = {
        appRepoLocation:"https://abr-app.travoxis.com",
        backendUrl: "http://localhost:3100",
        appBackendUrl : "http://localhost:4040",
        jsReportUrl: "http://localhost:5488"
    };
    if(process.env.REACT_APP_RESORT ==='ARCADIA-TEST'){
        exportResult.appRepoLocation = "https://sg-app.arcadiabeachresort.com.my";
        exportResult.backendUrl = "https://sg-admin-api.arcadiabeachresort.com.my";
        exportResult.appBackendUrl = "https://sg-app-api.arcadiabeachresort.com.my";
        exportResult.jsReportUrl= "https://sg-report.arcadiabeachresort.com.my";
    }
    if(process.env.REACT_APP_RESORT ==='ARCADIA-PROD'){
        exportResult.appRepoLocation = "https://app.arcadiabeachresort.com.my";
        exportResult.backendUrl = "https://admin-api.arcadiabeachresort.com.my";
        exportResult.appBackendUrl = "https://app-api.arcadiabeachresort.com.my";
        exportResult.jsReportUrl= "https://report.arcadiabeachresort.com.my";
    }
  
    return exportResult;
}